<template>
    <ul>
        <area-tour-block
            v-for="row in rows"
            :layer="layer"
            :key="row.id"
            :row="row"
            @hover="onHover"
            @click="onClick"
        />
    </ul>
</template>

<script>
import { get } from 'utils/lodash';
import AreaTourBlock from './components/block';
export default {
    components: {
        AreaTourBlock,
    },
    props: {
        layer: {
            type: Object,
            required: true,
        },
        map: {
            type: Object,
            required: true,
        },
    },
    computed: {
        rows() {
            return get(this.layer, 'geoJson.features');
        },
    },
    methods: {
        onClick(row) {
            this.$emit('click', row);
            let inBounds = this.map.map.getBounds().contains(row.geometry.coordinates);

            if (!inBounds || this.map.map.getZoom() < 14) {
                this.map.map.flyTo({
                    center: row.geometry.coordinates,
                    zoom: 14,
                });
            }
        },
        onHover(row) {
            this.$events.$emit('map:popup:enter', {
                coordinates: row.geometry.coordinates,
                html: row.properties.name,
            });
        },
    },
};
</script>
