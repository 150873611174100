<template>
    <li class="feature" @mouseover="onMouseOver" @click.stop="onClick">
        <div class="feature-details-wrapper">
            <position :icon="layer.icon" :position="properties.order" large />
            <div class="info">
                <h6 class="feature-header">{{ name }}</h6>
                <p v-if="description" class="feature-description text-truncate" v-html="description" v-linkify />
            </div>
            <button class="arrow" />
        </div>
    </li>
</template>

<script>
import { get, debounce } from 'utils/lodash';
import Position from './position';
export default {
    components: {
        Position,
    },
    props: {
        row: {
            type: Object,
            required: true,
        },
        layer: {
            type: Object,
            required: true,
        },
    },
    computed: {
        properties() {
            return get(this.row, 'properties');
        },
        name() {
            return get(this.properties, 'name');
        },
        description() {
            return get(this.properties, 'description');
        },
    },
    methods: {
        onMouseOver: debounce(function () {
            this.$emit('hover', this.row);
        }, 250),
        onClick() {
            this.$emit('click', this.row);
        },
    },
};
</script>

<style lang="scss" scoped>
@import 'scss/global/_variables';

.feature-details-wrapper {
    display: grid;
    grid-template-columns: 44px minmax(0, 1fr) 44px;

    .info {
        margin-left: 22px;

        .feature-header {
            padding-right: none !important;
            font-family: Avenir;
            font-size: 16px !important;
            font-weight: 500 !important;
            color: #505152 !important;
        }
    }

    .arrow {
        display: inline-block;
        height: 44px;
        width: 44px;
        background: url(#{$distImagesDir}right-arrow.svg) center no-repeat;
        background-size: 14px;
        align-self: center;
        justify-self: center;

        &.layer-expanded {
            background: url(#{$distImagesDir}down-arrow.svg) center no-repeat;
        }
    }
}
</style>
